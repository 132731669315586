/*!------------------------------------------------------------------
[MAIN STYLESHEET]

Template Name: Wijaya Trans Website
Description: Wijaya Trans Website
-------------------------------------------------------------------*/

/*------------------------------------------------------------------
[TABLE OF CONTENTS]
-------------------------------------------------------------------*/

@import 'mixins';
@import 'variables';
@import 'typography';
@import 'common';
@import 'buttons';
@import 'templates/navigation';
@import 'templates/main';